import useSWR from "swr";
import humps from "humps";

import client from "../helpers/apiClient";
import { AxiosHeaders } from "axios";

export default function useRequest<T>(
  request: string,
  {
    initialData,
    revalidateOnMount,
  }: {
    initialData?: T;
    revalidateOnMount?: boolean;
  } = {},
  params?: Record<string, unknown>
) {
  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useSWR(
    request && JSON.stringify(request),
    () => client(request, params && { params: humps.decamelizeKeys(params) }),
    {
      fallbackData: initialData && {
        config: { headers: {} as AxiosHeaders },
        status: 200,
        statusText: "InitialData",
        headers: {},
        data: initialData,
      },
      revalidateOnMount: revalidateOnMount && revalidateOnMount,
    }
  );

  return {
    data: response && response.data,
    response,
    error,
    isValidating,
    mutate,
  };
}
